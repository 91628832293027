@import '~office-ui-fabric-react/dist/sass/References.scss';

.ItemInspeccionContainer {
    margin: 20px 30px;
    text-align: center;
    display: block;
    position: relative;
}

.informacionGeneral {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    h1 {
        border-bottom: 2px solid $ms-color-themeDark;
        font-weight: bold;
        font-size: 15px;
        @include ms-fontColor-themeDark;
    }
    .informacionGeneralContent {
        padding: 10px;
    }
}

.VistaDocumentos{
    width: 100%;
    text-align: left;
    margin-top: 10px;
    h1 {
        border-bottom: 2px solid $ms-color-themeDark;
        font-weight: bold;
        font-size: 15px;
        @include ms-fontColor-themeDark;
    }
    .VistaDocumentosContent {
        .DocumentacionContainer{
            h3 {
                font-size: 15px;
                @include ms-fontColor-themeDark;
            }
            //.GrillaDocumentos{}
        }
    }
}

.CargaDocumentos{
    width: 100%;
    text-align: left;
    margin-top: 20px;
    h1 {
        border-bottom: 2px solid $ms-color-themeDark;
        font-weight: bold;
        font-size: 15px;
        @include ms-fontColor-themeDark;
    }
    .CargaDocumentosContent {
        padding: 10px;
    }
}

.panelContainer {
    padding: 5px;
    .infoDocumento{
      padding: 10px;
      h3{
        text-align: left;
        width: 100%;
        @include ms-font-xl;
        color: $ms-color-themeDark;
        font-weight: 500;
        border-bottom: 1px solid $ms-color-themeDark;
      }
      table{
        tr {
          td {
            padding: 5px 20px;
          }
        }
      }
    }
    .downloadDocto{
      text-align: right;
    }
  }