@import '~office-ui-fabric-react/dist/sass/References.scss';

.menuEtapas{
    min-height: 100px;
    margin: 0 auto;
    padding: 0;
    width: 850px;
    display: block;
}

.progressbar {
    counter-reset: step;
    margin: 0 auto;
    li {
        list-style-type: none;
        width: 20%;
        float: left;
        font-size: 15px;
        position: relative;
        text-align: center;
        color: $ms-color-neutralLight;
        &::after{
            width: 100px;
            height: 3px;
            content: "";
            position: absolute;
            top: 20px;
            left: -50px;
            z-index: 0;
            background-color: $ms-color-themeLight;
        }
        &::before{
            @include ms-fontColor-white;
            width: 40px;
            height: 40px;
            content: counter(step);
            counter-increment: step;
            line-height: 36px;
            border: none;
            display: block;
            text-align: center;
            margin: 0 auto 9px;
            border-radius: 50%;
            font-size: 21px;
            font-family: helvetica;
            padding: 2px;
            z-index: 3000;
            background-color: $ms-color-themeLight;
        }
        &:first-child{
            &::after{
                content: none;
            }
        }
        &:hover {
            color:$ms-color-themeDarker;
            text-decoration: none;
        }
        &.active {
            color: $ms-color-themePrimary;
            font-weight: 700;
            text-decoration: none;
            &::before{
                background-image: url('../../../img/check.png');
                color: $ms-color-themePrimary;
                background-position: 50%;
                font-size: 0;
                background-size: 20px;
                background-repeat: no-repeat;
                background-color: $ms-color-themePrimary;
            }
            + ::after{
                background-color: $ms-color-themePrimary;
            }
        }
        &.actual{
            &::before{
                @include ms-fontColor-white;
                font-weight: 700;
                text-decoration: none;
                background-color: $ms-color-themePrimary;
                border-color: $ms-color-themePrimary;
            }
            span {
                color: $ms-color-themeSecondary;
                font-weight: 700;
            }
        }
        .seleccionada{
            border-bottom: 5px solid $ms-color-themePrimary;
        }
    }
}