@import '~office-ui-fabric-react/dist/sass/References.scss';

.ItemInspeccionHomologacion {
    margin: 20px;
    padding: 0;
    text-align: center;
    h1 {
        border-bottom: 2px solid $ms-color-themeDark;
        font-weight: bold;
        font-size: 15px;
        @include ms-fontColor-themeDark;
        text-align: left;
    }
    .InformacionHomologacion {
        margin: 20px 10px;
        padding: 10px;
        border: 1px solid $ms-color-themePrimary;
        .infoContent {
            background-color: $ms-color-themePrimary;
            color: $ms-color-white;
            table{
                width: 100%;
                tbody{
                    tr{
                        td.key{
                            padding: 2px 5px;
                            font-weight: 800;
                            text-align: right;
                        }
                        td.value{
                            padding: 2px 5px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .DocumentosHomologacion {
        margin: 20px 10px;
    }
    .CargaDocumentos{
        margin: 20px 10px;
    }
    .DocumentosInspeccion{
        margin: 20px 10px;
    }

    .Aprobado{
        margin: 20px 10px;
        padding: 15px;
        border: 1px solid $ms-color-green;
        .aprobadoContent{
            padding: 10px;
            background-color: $ms-color-green;
            color: $ms-color-white;
            font-weight: 900;
            font-size: large;
        }
    }
    .Rechazado{
        margin: 20px 10px;
        padding: 15px;
        border: 1px solid $ms-color-red;
        .rechazadoContent{
            padding: 10px;
            background-color: $ms-color-red;
            color: $ms-color-white;
            font-weight: 900;
            font-size: large;
        }
    }
}