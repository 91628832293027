@import '~office-ui-fabric-react/dist/sass/References.scss';

.ItemHomologacion {
    margin: 20px;
    padding: 0;
    text-align: center;
}

.Rechazado{
  border: 2px solid $ms-color-red;
  padding: 8px 10px;
  margin: 10px 0;
  .content {
    padding: 10px;
    background-color: $ms-color-red;
    color: $ms-color-white;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.Solicitado{
  border: 2px solid $ms-color-yellow;
  padding: 8px 10px;
  margin: 10px 0;
  .content {
    background-color: $ms-color-yellow;
    color: $ms-color-black;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.Aprobado {
  border: 2px solid $ms-color-green;
  padding: 8px 10px;
  margin: 10px 0;
  .content {
    background-color: $ms-color-green;
    color: $ms-color-white;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.ResumenHomologacion {
    margin: 5px;
    padding: 20px;
    border: 1px solid $ms-color-themePrimary;
    .ResumenContainer{
        background-color: $ms-color-themePrimary;
        color: $ms-color-white;
        h2 {
            text-align: left;
            font-size: large;
            padding-top: 4px;
            padding-left: 20px;
            padding-bottom: 2px;
            border-bottom: 1px solid $ms-color-themeTertiary;
        }
        table {
          width: 100%;
          tr {
            td {
              padding: 3px 7px;
              font-size: smaller;
              &.key{
                text-align: right;
                font-weight: bold;
                width: 50%;
              }
              &.val{
                text-align: left;
              }
            }
          }
        }
    }
}

.MainCapitulos {
    margin-top: 10px;
    .menu{
        margin: 0;
    }
    .container {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 65vh;
    }
}

.RequisitosTecnicos {
    padding: 40px;
    .ButtonBox{
        text-align: right;
        margin: 15px 0;
    }
}

.RequisitosCalidad {
  padding: 40px;
  .ButtonBox{
      text-align: right;
      margin: 15px 0;
  }
}

.RequisitosEstrategicos {
  padding: 40px;
  .ButtonBox{
      text-align: right;
      margin: 15px 0;
  }
}

.panelContainer {
  padding: 5px;
  .infoDocumento{
    padding: 10px;
    h3{
      text-align: left;
      width: 100%;
      @include ms-font-xl;
      color: $ms-color-themeDark;
      font-weight: 500;
      border-bottom: 1px solid $ms-color-themeDark;
    }
    table{
      tr {
        td {
          padding: 5px 20px;
        }
      }
    }
  }
  .downloadDocto{
    text-align: right;
    margin-top: 15px;
  }
}

.Muestra {
  padding: 40px;
  div {
    &:first-child{
      margin:0;
    }
  }
}

.Inspeccion {
  padding: 40px 40px 200px; // el último es aire al final de la página
  .InspeccionItem{
    border: 1px solid $ms-color-themeLight;
    .inspeccionItemContent{
      table{
        width: 100%;
        tbody{
          td.texto{
            text-align: right;
            padding-right: 20px;
          }
          td.valor{
            text-align: left;
          }
        }
      }
      .comentarioBox{
        padding: 8px 15px;
      }
      .listadoBox{
        padding: 8px 15px;
      }
    }
  }
}

.contentVistaPrevia{
  margin: 10px 150px;
  display: block;
  border: 2px solid $ms-color-themeLight;
  text-align: justify;
  text-justify: inter-word;
}

.contentVistaPreviaPrint{
  margin: 10px 60px;
  display: block;
  border: 2px solid $ms-color-themeLight;
  text-align: justify;
  text-justify: inter-word;
}