@import '~office-ui-fabric-react/dist/sass/References.scss';

.SeparadorContent{
    width: 100%;
    border-bottom: 2px solid $ms-color-themePrimary;
    min-height: 1px;
    margin: 10px 0px ;
    text-align: left;
    padding: 5px 0;
    h3 {
        color: $ms-color-themePrimary;
        font-size: x-large;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }
    &.SubTitleColor {
        border-bottom: 1px solid $ms-color-themeTertiary;
        border-top: 1px solid $ms-color-themeTertiary;
        padding: 5px 10px;
        h3 {
            color: $ms-color-themeTertiary;
            font-size: medium;
            font-weight: bold;
            padding: 0;
            margin: 0;
        }
    }
}