@import '~office-ui-fabric-react/dist/sass/References.scss';


.GrillaHomologacion {
    margin: 20px;
    padding: 0;
    text-align: center;
}

.cargaBrochure {
    padding: 15px 0;
}