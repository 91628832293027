@import '~office-ui-fabric-react/dist/sass/References.scss';

.Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 150;
    background-color: $ms-color-white;
    text-align: center;
}

.LoadingFullScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3000;
    overflow: none;
    background-color: $ms-color-white;
    text-align: center;
}

.LoadingContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}