@import '~office-ui-fabric-react/dist/sass/References.scss';

.itemSuministro {
  .container {
    //max-width: 700px;
    margin: 0px;
    padding: 0px;
  }
  .permission{
    text-align: right;
    margin-top: -45px;
  }
}

.resumenContainer {
  border: 2px solid $ms-color-themeDark;
  margin: 0 40px;
  padding: 10px;

  .row {
    @include ms-Grid-row;
    @include ms-fontColor-white;
    background-color: $ms-color-themeDark;
    padding: 7px 50px;
    margin: 0px !important;
  }

  .columnLeft {
    font-size: medium;
    @include ms-Grid-col;
    @include ms-lg5;
    @include ms-lgPush1;
  }

  .columnRight {
    font-size: medium;
    @include ms-Grid-col;
    @include ms-lg5;
    @include ms-lgPush1;
  }

}

.panelContainer {
  padding: 5px;
  .infoDocumento{
    padding: 10px;
    h3{
      text-align: center;
      width: 100%;
      font-weight: bold;
      @include ms-font-xl;
      color: $ms-color-themeDark;
    }
    table{
      tr {
        td {
          padding: 5px 20px;
        }
      }
    }
  }
}

.protocolosPruebas {
  .onSuccess {
    border: 2px solid $ms-color-green;
    margin: 10px;
    padding: 5px;
    h2 {
      margin: 10px;
      padding: 10px;
      text-align: center;
      background-color: $ms-color-green;
      @include ms-fontColor-white;
    }
  }

  .onUnsuccess {
    border: 2px solid $ms-color-red;
    margin: 10px;
    padding: 5px;
    h2 {
      margin: 10px;
      padding: 10px;
      text-align: center;
      background-color: $ms-color-red;
      @include ms-fontColor-white;
    }
  }
}

.resultadosRecepcion {
  .onSuccess {
    border: 2px solid $ms-color-green;
    margin: 10px;
    padding: 5px;
    h2 {
      margin: 10px;
      padding: 10px;
      text-align: center;
      background-color: $ms-color-green;
      @include ms-fontColor-white;
    }
  }

  .onUnsuccess {
    border: 2px solid $ms-color-red;
    margin: 10px;
    padding: 5px;
    h2 {
      margin: 10px;
      padding: 10px;
      text-align: center;
      background-color: $ms-color-red;
      @include ms-fontColor-white;
    }
  }

  .onWait {
    border: 2px solid $ms-color-yellow;
    margin: 10px;
    padding: 5px;
    h2 {
      margin: 10px;
      padding: 10px;
      text-align: center;
      background-color: $ms-color-yellow;
      @include ms-fontColor-white;
    }
  }
}

.EntregablesContent {
  .tipoDocumentosContainer {
    margin: 0 30px;
    h3 {
      width: 100%;
      color: $ms-color-themeDark;
      border-bottom: 1px solid $ms-color-themeDark;
    }
    .buttonBox {
      text-align: right;
    }
  }
  .calculoAvanceContainer{
    margin: 0 25px;
    height: auto;
    .calculoAvance {
      margin: 10px;
      h3 {
        font-size: large;
        font-weight: bold;
        color: $ms-color-themeDark;
        padding: 5px;
        margin: 0px;
      }
      .listaRequeridos{
        padding: 5px;
        .nombreRequeridos {
          font-size: medium;
        }
        .imagenRequeridos {
          float: left;
          padding-right: 5px;
        }
      }
    }
  }
}

ul.CheckSuccessTooltip {
  list-style: none;
  margin: 0;
  padding: 5px;
  li {
    margin: 3px 0;
    &.success{
      color: green;
    }
    &.unsuccess{
      color: red;
    }
  }
}

.abrirDocumento{
  color: white;
  text-decoration: none;
}
div.boxButtonAdmin {
  text-align: right;
}
