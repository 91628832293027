@import '~office-ui-fabric-react/dist/sass/References.scss';

.uploadFileContent{
    margin: 20px 5px !important;
    @include ms-Grid-row;
    .dropZoneBox{
        width: 100%;
        @include ms-Grid-col;
        @include ms-lg12;
        .normal{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 101px;
            border: 2px dashed $ms-color-themePrimary;
            cursor: pointer;
        }
        .over {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            border: 2px dashed $ms-color-themeSecondary;
            cursor: pointer;
        }
    }

    .tempList{
        @include ms-Grid-col;
        @include ms-lg12;
        text-align: right;
    }

    .buttonBox{
        @include ms-Grid-col;
        @include ms-lg12;
        text-align: right;
        margin: 15px 0;
    }

    .messageArray{
        h3{
            width: 100%;
            //border-bottom: 1px solid $ms-color-themePrimary;
            color: $ms-color-themePrimary;
            font-size: large;
            font-weight: bold;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                font-size: small;
                padding: 3px 0px;
                padding-left: 5px;
            }
        }
    }
}
