@import '~office-ui-fabric-react/dist/sass/References.scss';

.ItemSuministroContainer {
    margin: 15px;
    display: flex;
    flex-direction: column;

    h2 {
        @include ms-fontColor-themePrimary;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
    }
}

.containerEtapas {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1px;
    width: 100%;
}

.whiteSpace {
    width: 12%;
}

.bordeAvance {
  border: solid 1px #0078d4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedorAvance {
  @include ms-fontColor-themePrimary;
  color: white;
  margin: 7px;
  padding: 10px;
  background: #0078d4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.AntecedentesContainer {
    margin: 10px 100px;
}

.EntregablesContainer {
    margin: 10px 100px;
    h4 {
      font-size: medium;
      font-weight: 800;
    }
    .listaRequeridos{
      padding-bottom: 8px;
      .nombreRequeridos {
        font-size: medium;
      }
      .imagenRequeridos {
        float: left;
        padding-right: 5px;
      }
    }
}

.protocolosPruebasContainer {
  margin: 10px 100px;
    .onSuccess {
      border: 2px solid $ms-color-green;
      margin: 10px;
      padding: 5px;
      h2 {
        margin: 10px;
        padding: 10px;
        text-align: center;
        background-color: $ms-color-green;
        @include ms-fontColor-white;
      }
    }
  
    .onUnsuccess {
      border: 2px solid $ms-color-red;
      margin: 10px;
      padding: 5px;
      h2 {
        margin: 10px;
        padding: 10px;
        text-align: center;
        background-color: $ms-color-red;
        @include ms-fontColor-white;
      }
    }
  }

.DocNacIntContainer {
  margin: 10px 100px;
  h4 {
    font-size: medium;
    font-weight: 800;
  }
  .listaRequeridos{
    padding-bottom: 8px;
    .nombreRequeridos {
      font-size: medium;
    }
    .imagenRequeridos {
      float: left;
      padding-right: 5px;
    }
  }
}

.panelContainer {
  padding: 5px;
  .infoDocumento{
    padding: 10px;
    h3{
      text-align: left;
      width: 100%;
      @include ms-font-xl;
      color: $ms-color-themeDark;
      font-weight: 500;
      border-bottom: 1px solid $ms-color-themeDark;
    }
    table{
      tr {
        td {
          padding: 5px 20px;
        }
      }
    }
  }
  .downloadDocto{
    text-align: right;
  }
}

.avisoContainer{
  border: 2px solid #0078d4;
  padding: 20px;
  margin-top: 10px;
  span{
    font-weight: bold;
  }
}

.Recepcion {
  margin-bottom: 100px;
  margin-top: 10px;
  .infoDocumento{
    border: 1px solid $ms-color-themeLight,
  }
  .contentRecepcion{
    margin: 10px 150px;
    display: block;
    border: 2px solid $ms-color-themeLight,
  }
  h2{
    margin: 0;
    padding: 0;
    text-align: left;
    background-color: transparent;
    color: black;
  }
}