@import '~office-ui-fabric-react/dist/sass/References.scss';

.LoginContainer {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    margin: 0px !important;
    padding: 0px !important;
    .LogoContent {
        background-color: $ms-color-white;
        height: 100vh;
        width: 100vw;
        display: block;
        position: absolute;
        overflow: hidden;
    }
    .LoginContent {
        background-color: $ms-color-themeLight;
        border: 2px solid $ms-color-themeDark;
        text-align: center;
        z-index: 3000;
        position: relative;
        height: 143px;
        margin-top: 85vh;
        padding: 51px 0;
        overflow: hidden;
        button {
            padding: 20px 85px;
        }
        .ErrorMsje {
            color: $ms-color-red;
            text-align: center;
        }
    }

    .divEnlace{
        margin-top: 5px;
        cursor: pointer;
    } 
}
