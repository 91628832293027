@import '~office-ui-fabric-react/dist/sass/References.scss';

.comentarios {
  .comentariobox {
    margin: 5px 0;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    list-style: none;
    border: solid 2px $ms-color-themeDark;
    padding: 5px;
      li {
        background-color: $ms-color-themePrimary;
        @include ms-fontColor-white;
        margin: 10px 3px;
        padding: 5px 15px;
        display: block;
        min-width: 300px;
        &.externo{
          background-color: $ms-color-neutralTertiary;
          border-radius:  15px 15px 15px 0px;
          text-align: left;
          float: left;
        }
        &.interno{
          border-radius:  15px 15px 0px 15px;
          text-align: right;
          float: right;

        }
        .comentario {
          @include ms-font-l;
          @include ms-fontColor-white;
        }
        .autor{
          @include ms-font-s;
          @include ms-fontColor-white;
        }
      }
  }
  .nuevocomentariobox{
    float: none;
    width: 100%;

    .row {
      @include ms-Grid-row;
      @include ms-fontColor-white;
      padding: 5px 20px;
    }
  
    .columnTitle {
      @include ms-Grid-col;
      @include ms-lg9;
    }
    .columnButton {
      margin-top: 28px;
    }
    .columnButton input {
      color: black;
    }
    .columnMax {
      @include ms-Grid-col;
      @include ms-lg12;
    }
  
  }
}

.icono{
  color:black;
}
.comentarioInfo {
  border: 1px solid $ms-color-themePrimary;
  background-color: $ms-color-themePrimary;
  padding: 10px;
  @include ms-fontColor-white;
  h3 {
    @include ms-font-xl;
    font-weight: bold;
    margin-left: 20px;
    text-transform: uppercase;
  }
  li {
    @include ms-font-s;
  }
}

.contenedorBotones{
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.contenedorBotones input{
  margin-left: 30px;
}

.enlaceDescarga:hover{
  cursor: pointer;
}
.enlaceDescarga{
  font-style: italic;
  display: flex;
}
.contenedorComentario a{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contenedorComentario a p{
  margin: 0;
  padding: 0;
}
