@import '~office-ui-fabric-react/dist/sass/References.scss';

.NotFoundContainer {
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    position: relative;
    background-color: $ms-color-themeLight;
    @include ms-fontColor-white;
}