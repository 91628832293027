@import '~office-ui-fabric-react/dist/sass/References.scss';

.AccordionContainer{
    .AccordionSection{
        display: flex;
        flex-direction: column;
        .Accordion{
            background-color: $ms-color-themeLighter;
            color: $ms-color-themeDark;
            cursor: pointer;
            padding: 10px 15px;
            margin: 0;
            display: flex;
            justify-content: space-between;
            border: none;
            outline: none;
            transition: background-color 0.2s ease;
            &:hover, &.active{
                background-color: $ms-color-themeLight;
            }
            .title {
                font-size: medium;
                padding: 0 10px;
            }
            .icon{
                margin: 0 10px;
            }
        }
        .Content{
            background-color: $ms-color-white;
            overflow: hidden;
            transition: max-height 0.2s ease;
        }
    }
}
