@import '~office-ui-fabric-react/dist/sass/References.scss';

.MaestroMaterialesContainer {
    display: block;
    margin: 0;
    padding: 20px 10px;
    .Header{
        margin: 20px 0;
        .HeaderContainer{
            .Busqueda{
                display: flex;
                .elementos {
                    display: flex;
                    .elemento{
                        margin: 0 3px;
                        width: 200px;
                    }
                }
                .botonera {
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 0;
                }
            }
            .Configuracion {
                display: flex;
                .elementos {
                    width: 100px;
                }
            }
        }
    }
    .CardContainer{
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .Card {
            margin: 3px;
            border: 2px solid $ms-color-themeLight;
            padding: 0 10px;
            height: 230px;
            width: 190px;
            position: relative;
            overflow: hidden;
            p.code{
                text-align: right;
                font-size: small;
                font-weight: bold;
                margin: 0;
                padding: 8px 0px 5px;
            }
            .img{
                border: 1px solid $ms-color-themeLighter;
                width: 165px;
                height: 77px;
                text-align: center;
                vertical-align: middle;
                i {
                    font-size: 22px;
                    margin-top: 19px;
                }
                span {
                    font-size: 13px;
                    font-weight: bold;
                    display: block;
                }
                img {
                    width: 165px;
                    height: 77px;
                }
            }
            .description {
                margin: 10px 0 7px;
                padding: 0;
                text-align: justify;
                font-size: small;
                word-wrap: break-word;
                text-overflow: ellipsis;
                width: 167px;
                height: 60px;
                display: inline-block;
                overflow: hidden;
            }
            .bottonBox{
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 5px;
                i {
                    font-size: 20px;
                }
            }
        }
    }
    .Footer {
        margin-top: 20px;
        margin-bottom: 100px;
        position: relative;
        .FooterContainer {
            display: flex;
            position: absolute;
            right: 20px;
            i {
                font-size: 24px;
                margin: 5px 6px;
            }
            button{
                margin: 0;
                i {
                    font-size: 18px;
                }
            }
            .numerIcon{
                height: 20px;
                font-size: 13px;
                display: block;
                padding: 5px;
                margin: 0px 3px;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background-color: $ms-color-neutralLighter;
                }
                &.actual{
                    text-decoration: underline;
                    font-weight: bold;
                }

            }
        }
    }
}

.PanelMaterial {
    padding: 10px;
    .InformacionMaterial{
        padding: 0 5px;
        table{
            width: 100%;
            tr{
                td.titulo{
                    font-weight: 700;
                }
                td.contenido{
                    text-align: justify;
                }
            }
        }
    }
    .DocumentosMaterial{
        padding: 0 5px;
    }
    .CargaDoctosMaterial{
        padding: 0 5px;
    }
}