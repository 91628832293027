@import '~office-ui-fabric-react/dist/sass/References.scss';

.headerContent{
    margin: 0 !important;
    @include ms-Grid-row;
}

.logoContent{
    float: left;
    margin-top: 5px;
    display: block;
}

.navContet{
    display: block;
}